import React, { useRef } from "react";
import { MdAlternateEmail } from "react-icons/md";
import emailjs from "emailjs-com";
import { BsWhatsapp } from "react-icons/bs";
import "./Contact.css";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gq9f8fr",
        "template_9jh7e9b",
        form.current,
        "wD4aPUKr9sNI8Kiua"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact">
      <div className="section__wrapper contact__container">
        <div className="section__header">
          <h2 className="primary__title__2" style={{textAlign:"center"}}>Contact Me!</h2>
          <p className="text__muted__contact description">
          Ready to transform your digital solutions? 
          With expertise in full-stack development, 
          I can help you build dynamic web applications, 
          optimize e-commerce platforms, and create real-time 
          communication tools. Let's discuss your project, 
          explore innovative solutions, or simply connect.
          </p>
        </div>
        <div className="contact__group">
          <div className="contact__options">
            <article className="contact__option">
              <MdAlternateEmail className="contact__icon" />
              <h3>Email</h3>
              <h5>safaaru@outlook.com</h5>
              <a
                href="mailto:safaaru@outlook.com"
                target="_blank"
                className="btn btn__primary"
                rel="noreferrer"
              >
                Send an email
              </a>
            </article>

            <article className="contact__option">
              <BsWhatsapp className="contact__icon" />
              <h3>WhatsApp</h3>
              <h5>+1 (437) 987 0592</h5>
              <a
                href="https://api.whatsapp.com/send?phone=4379870592"
                target="_blank"
                className="btn btn__primary"
                rel="noreferrer"
              >
                Send a message
              </a>
            </article>
          </div>

          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              name="name"
              placeholder="Your Full Name"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              required
              rows={7}
            ></textarea>
            <button type="submit" className="btn btn__primary">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
