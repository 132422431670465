import React, { useEffect, useState, useRef } from "react";
import "./Facts.css";
import Odometer from "react-odometerjs";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Facts = () => {
  const [experience, setExperience] = useState(0);
  const [projects, setProjects] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setExperience(4);
      setProjects(120);
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const container = useRef(null);
  gsap.registerPlugin(useGSAP);
  useGSAP(
    () => {
      const timeLine = gsap.timeline();
      timeLine.from(".fact__item", {
        delay: 1.5,
        opacity: 0,
        x: -100,
        stagger: 0.5,
      });
    },
    { scope: container }
  );

  return (
    <div className="fact__container" useRef={container}>
      {/*  
      <div className="fact__item">
        <div className="count__container">
          <Odometer value={experience} />
          <span className="indicator">+</span>
        </div>
        <p className="color__primary__2">Years Of Experience</p>
      </div>
      */}

      <div className="fact__item">
        <div className="count__container">
          <Odometer value={projects} />
          <span className="indicator">+</span>
        </div>
        <p className="color__primary__2">Completed Projects</p>
      </div>
    </div>
  );
};

export default Facts;
