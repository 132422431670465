import React, { useState, useRef, useEffect } from "react";
import "./Card.css";
// import { AiOutlineShareAlt } from "react-icons/ai";

const Card = (props) => {
  const [openStackExpandBar, setOpenStackExpandBar] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const stackExpandRef = useRef(null);

  const toggleDescription = () => {
    setDescriptionExpanded(!descriptionExpanded);
  };

  const handleClickOutside = (event) => {
    if (
      stackExpandRef.current &&
      !stackExpandRef.current.contains(event.target)
    ) {
      setOpenStackExpandBar(false);
    }
  };

  useEffect(() => {
    if (openStackExpandBar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openStackExpandBar]);

  return (
    <div className="card">
      <div className="picture">
        <img src={props.image} alt={props.title} />
      </div>
      <div className="card__details">
        <div className="card__details__top">
          <h2 className="primary__title__3">{props.title}</h2>
        </div>
        <div className="card__details__middle">
          <p
            className={`description ${
              descriptionExpanded ? "expanded" : "collapsed"
            }`}
            dangerouslySetInnerHTML={{ __html: props.data.description }}
          ></p>
          <div className="see-more" onClick={toggleDescription}>
            {descriptionExpanded ? "See Less" : "See More"}
          </div>
        </div>
        <div className="card__details__bottom">
          <div className="stack__container">
            <div className="stack__left"></div>
            <div className="stack__right">
              <div className="stack__box__container">
                {props.stack.slice(0, 4).map((list, index) => (
                  <div key={index} className="stack__box">
                    <div className="stack__icon__container">
                      <span
                        className="stack__icon"
                        style={{ color: list.iconColor }}
                      >
                        {list.icon}
                      </span>
                      <span className="stack__name">{list.name}</span>
                    </div>
                  </div>
                ))}
              </div>
              {props.stack.length > 4 && (
                <div className="stack__view__more">
                  <div
                    className="more__btn"
                    onClick={() => setOpenStackExpandBar(!openStackExpandBar)}
                  >
                    See More
                  </div>
                  <div
                    className={`stack__expand__box ${
                      openStackExpandBar ? "open__stack__expand__box" : ""
                    }`}
                    ref={stackExpandRef}
                  >
                    <h3 className="title" style={{ textAlign: "center" , fontSize:"18px" }}>
                      More Stack Used
                    </h3>
                    <div className="stack__box__container">
                      {props.stack.slice(4).map((list, index) => (
                        <div key={index} className="stack__box" id="stack__BOX">
                          <div className="stack__icon__container">
                            <span
                              className="stack__icon"
                              style={{ color: list.iconColor }}
                            >
                              {list.icon}
                            </span>
                            <span className="stack__name">{list.name}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="button__container">
            <a
              href={props.demoLink}
              target="_blank"
              className="btn btn__primary"
              rel="noreferrer"
            >
              Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
