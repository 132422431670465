import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import {
  react_certificate,
  ai_certificate,
  ios16_certificate,
  java_certificate,
  python_object_certificate,
  python_testing_certificate,
  full_stack_certificate,
  aspNET_certificate,
  typeScript_certificate,
  vueJs_certificate,
  diploma_certificate,
  nexJS_certificate,
} from "../../images";
import "./Certifications.css";

const certifications__data = [
  {
    avatar: full_stack_certificate,
    name: "Learning Full-Stack JavaScript Development: MongoDB, Node, and React",
    detail: `Learn full-stack JavaScript development by building 
      a web application with MongoDB, Node.js, and React.js. 
      Learn how to use Node as a web server and an API server, 
      how to consume data and build user interfaces with React,
      and how to read and write data with a MongoDB database.`,
  },
  {
    avatar: react_certificate,
    name: "ReactJs Essential Training",
    detail: `In this course, Eve Porcello introduces the basics of 
    the React library using the most modern syntax and best 
    practices for creating React components. Along the way, 
    learn how to set up Chrome tools for React; create new 
    components; work with the built-in Hooks in React; use 
    the Create React App to run tests, and more. `,
  },
  {
    avatar: typeScript_certificate,
    name: "TypeScript Essential Training",
    detail: `In this course, Jess Chadwick teaches you how to leverage
      the full power of the TypeScript language in your JavaScript applications.
      Revisit some of the JavaScript fundamentals before turning to the data 
      types, classes, generics, modules, and decorators that are unique to
      TypeScript. Get tips on defining complex types, extending and extracting
      metadata from existing types, and working with JavaScript modules
      to make your code more efficient. `,
  },
  {
    avatar: vueJs_certificate,
    name: "VueJs: Creating and Hosting a Full-Stack Site",
    detail: `In this course, instructor Shaun Wassell helps you enhance
         your development tool kit by showing how to leverage your
         existing Vue.js skills to build a full-stack ecommerce site.
         Shaun guides you through the process, showing how to use 
         Vue.js to build a simple front end; Node.js and 
         MongoDB to construct the back end`,
  },
  {
    avatar: diploma_certificate,
    name: "Computer Science Diploma with Honours",
    detail:
      "George Brown College, Toronto, ON, Canada. Graduated with Honours.",
  },
  {
    avatar: aspNET_certificate,
    name: "Building Web APIs with ASP.NET Core in.NET",
    detail: `In this course, instructor Christian Wenz shows you how to get started,
       taking you step by step through the process of building a web 
       API with ASP.NET Core 6, the open-source framework for Windows, macOS,
       and Linux. Christian explains how routing works and how data is modeled in ASP.NET Core`,
  },
  {
    avatar: nexJS_certificate,
    name: "Learning Next.js",
    detail: `In this course, instructor Sandy Ludosky shows you 
       how to get the best developer experience from your Next.js
       project—with zero configuration, zero user headaches, 
       and lightning fast, dynamic page speeds. 
       Explore the benefits of server-side rendering with web frameworks like Next.js.
       Sandy walks you through basic features such as pages, data fetching, 
       and layout options; advanced topics like dynamic routes and API routes;
       and how to build and stylize a React project from start to finish
       so you can create a Next.js app on your own.`,
  },
  {
    avatar: ios16_certificate,
    name: "iOS 16 Development Essential Training",
    detail: `In this course, Todd Perkins gets you started down the path 
       of iOS development, giving you the knowledge to help you create your own apps, even 
       if you're starting out with zero iOS experience. 
       Todd teaches you the fundamentals of XCode, Apple's official tool for developing iOS applications.`,
  },
  {
    avatar: ai_certificate,
    name: "Training Neural Networks in Python",
    detail: `Neural networks are widely used in everyday applications
       like online shopping, weather forecasting, and smartphones. 
       This course by Eduardo Corpeño teaches the inner workings 
       of neural networks in Python, allowing you to fully understand
       the algorithms behind them. Although there are professional 
       tools that allow you to train neural networks from a high-level perspective,
       this course gives you a chance to tap into the details of
       the algorithms behind neural networks.`,
  },
  {
    avatar: python_testing_certificate,
    name: "Unit Testing in Python",
    detail: `Unit testing—which involves testing small, isolatable pieces 
      of code—can help you catch and fix bugs before they crop up in your
      final product. And by adopting test-driven development (TDD) and 
      writing unit tests before production code, you can take even greater
      responsibility for your project's quality. Curious about how to best
      approach unit testing in your own Python projects? This course shows 
      you how. Join instructor Jasmine Omeke as she goes over TDD techniques 
      for Python projects using the pytest testing framework and a Docker container.`,
  },
  {
    avatar: java_certificate,
    name: "Java Object-Oriented Programming Concepts",
    detail: `In this course, Kathryn Hodge teaches the basics of object-oriented programming
       in Java, so you can write code that's secure, scalable, and easier to 
       troubleshoot. Kathryn begins by showing how object-oriented principles 
       are embedded into the Java language from the first code file you create. 
       She looks at how classes, instances, and constructors embody the idea 
       of representing real-life objects in code.`,
  },
  {
    avatar: python_object_certificate,
    name: "Python Object-Oriented Programming Concepts",
    detail: `The object-oriented programming (OOP) features 
    in Python make it easier to build programs of increasing 
    complexity and modularity. In this course with instructor Joe Marini, 
    learn how to apply core OOP principles to build programs that are 
    extensible and efficient. Joe starts with the basics of defining and 
    using classes and objects. Then he moves into more advanced features 
    like abstract base classes and how to implement interfaces. `,
  },
];

const Certifications = () => {
  return (
    <section id="certification">
      <div className="section__wrapper">
        <div className="section__header">
          <h2 className="primary__title__2" style={{textAlign:"center"}}>Certificates</h2>
          <p className="text__muted__certification description">
            Here are some of the professional certifications I have earned,
            showcasing my expertise and dedication to continuous learning and
            development in the field of web development and programming.
          </p>
        </div>
        <Swiper
          className="certification__container"
          modules={[Pagination]}
          spaceBetween={40}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            700: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {certifications__data.map(({ avatar, name, detail }, index) => (
            <SwiperSlide className="certification" key={index}>
              <div className="client__avatar">
                <img src={avatar} alt={detail} />
              </div>
              <h3 className="client__name">{name}</h3>
              <div className="client__detail__container">
                <small className="client__detail">{detail}</small>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Certifications;
