import Navbar from "./components/Navbar";
import Header from "./components/Header";
import About from "./components/About";
// import Services from "./components/Services";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Certifications from './components/Certifications/index';
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Capstone from "./components/Capstone/Capstone";

function App() {
  return (
    <>
    <Navbar />
    <Header/>
    <About/>
    {/* <Services/> */}
    <Skills />
    <Projects />
    <Capstone />
    <Certifications />
    <Contact />
    <Footer />
    </>
  );
}

export default App;

