import React from "react";
import { menu, socialHandles } from "../../data";
import { Link } from "react-scroll";
import "./Footer.css";

function Footer() {
  const scrollToTop = () => {
    const startY = window.scrollY;
    const duration = 1000; // Duration in ms
    let start;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const newY = Math.max(startY - startY * (progress / duration), 0);
      window.scrollTo(0, newY);
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  };

  return (
    <footer id="footer">
      <div className="section__wrapper">
        <ul className="nav__link__container">
          {menu.map((list, index) => (
            <Link
              activeClass="active"
              className="tab__item__footer"
              id="navigation__link"
              to={list.name.toLowerCase()}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              key={index}
            >
              {list.name}
            </Link>
          ))}
        </ul>
        <div className="social__handles__container">
          {socialHandles.map((list, index) => (
            <a
              href={list.link}
              target="_blank"
              rel="noreferrer"
              key={index}
              className="icon__container social__handles"
            >
              {list.icon}
            </a>
          ))}
        </div>
        <div className="copyright__container">
          <h3>&copy; 2024 Safa Aru. All rights reserved.</h3>
        </div>
        <button className="scroll__up" onClick={scrollToTop}>
          Scroll Up
        </button>
      </div>
    </footer>
  );
}

export default Footer;
