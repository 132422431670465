import React, { useRef } from "react";
import { profile1 } from "../../images";
import "./Header.css";
import Facts from "./Facts";
import resume from "../asset/resume.pdf";
import HeaderSocial from "./HeaderSocial";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Header = () => {
  const container = useRef(null);
  gsap.registerPlugin(useGSAP);

  useGSAP(
    () => {
      gsap.fromTo(
        ".profile__photo__container",
        {
          scale: 0.5,
          duration: 1,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: "sine.in",
        }
      );
      gsap.from(".intro__text", {
        fontSize: 100,
        duration: 1,
        delay: 1,
        ease: "sine.in",
      });

      const timeLine = gsap.timeline();
      timeLine
        .from(".header__info__top", {
          opacity: 0,
        })
        .from(".header__title", {
          opacity: 0,
          y: -30,
        })
        .from(".header__description", {
          opacity: 0,
        })
        .from(".btn", {
          x: -40,
          opacity: 0,
          stagger: 0.5,
        });
    },
    { scope: container }
  );

  return (
    <header id="header" className="blur-effect" ref={container}>
      <div className="stroke__text intro__text">HELLO</div>
      <div className="section__wraper header__container">
        <div className="column intro__container blur-effect">
          <div className="header__info">
            <div className="header__info__top">
              Hello There I'm{" "}
              <span className="color__primary__2">Safa Aru</span>
            </div>
            <div className="header__info__middle">
              <h1 className="primary__title header__title">
                {" "}
                I'M A FRONTEND DEVELOPER
              </h1>
              <p
                className="text__muted header__description"
                id="header__letter"
              >
                ⦿ Enthusiastic Frontend Developer with experience in JavaScript
                frameworks (React, Node.js, Angular), focusing on creating
                responsive, user-centric web applications and basic RESTful API
                development.
                <br />❂ Familiar with database management (MongoDB, SQL), user
                authentication (JWT), and Agile practices, aiming to deliver
                scalable solutions and dynamic user experiences.
              </p>
            </div>
            <Facts />
            <div className="header__info__bottom">
              <a href={resume} className="btn__primary" id="resume__class">
                Download CV
              </a>
              <HeaderSocial />
            </div>
          </div>
        </div>
        <div className="column profile__wrapper">
          <div className="profile__photo__container">
            <img src={profile1} alt="" className="profile__photo" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
