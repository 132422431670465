import { DiReact } from "react-icons/di";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress, SiMongodb } from "react-icons/si";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { SiJsonwebtokens } from "react-icons/si";
import { SiChartdotjs } from "react-icons/si";
import { FaBootstrap } from "react-icons/fa";
import { FaCcStripe } from "react-icons/fa";
import { BiLogoCss3 } from "react-icons/bi";
import { SiFirebase } from "react-icons/si";
import { MdMarkEmailRead } from "react-icons/md";
import { SiSocketdotio } from "react-icons/si";
import { FaVuejs } from "react-icons/fa";
import { SiRedux } from "react-icons/si";
import { PiFileCSharpFill } from "react-icons/pi";
import { TbFileTypeSql } from "react-icons/tb";
import { FaWpforms } from "react-icons/fa6";
import { SiMicrosoftaccess } from "react-icons/si";
import { SiMicrosoft } from "react-icons/si";
import { TbBrandReactNative } from "react-icons/tb";
import { SiGooglemaps } from "react-icons/si";
import { FaGooglePlusSquare } from "react-icons/fa";
import { SiPython } from "react-icons/si";
import { SiKeras } from "react-icons/si";
import { SiJupyter } from "react-icons/si";
import { SiPandas } from "react-icons/si";
import { SiGooglecolab } from "react-icons/si";
import { SiNumpy } from "react-icons/si";
import { SiScikitlearn } from "react-icons/si";
import { SiAngular } from "react-icons/si";
import { RiAngularjsLine } from "react-icons/ri";
import { SiApollographql } from "react-icons/si";

import erm_img from "./images/erm_img.png";
import ecom_img from "./images/ecom_img.png";
import mavna_trade from "./images/mavna_img.png";
import chat_img from "./images/chat_img.png";
import olive_trees from "./images/olive-trees.png";
import gbc_airlines from "./images/gbc-airlines.png";
import gbc_sporting from "./images/gbc-sporting.png";
import recipe_app from "./images/recipe-app.png";
import food_finder from "./images/food-finder.jpeg";
import employee_typescript from "./images/employee_typescript.png";

// import home_page from "./images/home-page.png";
import data_as_1 from "./images/data_as_1.png";
import data_as_2 from "./images/data_as_2.png";
import data_as_3 from "./images/data_as_3.png";
import bonus_as from "./images/bonus_as.png";

import employee_mng_img from "./images/employee-mng.jpeg";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaJava } from "react-icons/fa";
import { SiSpringboot } from "react-icons/si";

export const menu = [
  { name: "About" },
  // { name: "Services" },
  { name: "Skill" },
  { name: "Projects" },
  { name: "Capstone" },
  { name: "Certification" },
  { name: "Contact" },
];

export const projects = [
  {
    id: 1,
    title: "E-Commerce Application",
    image: ecom_img,
    category: "Freelance",
    data: {
      description: `❂ Engineered robust RESTful APIs using Node.js and Express, 
      facilitating seamless integration with frontend applications and third-party services.
      <br><br>
      ❂ Implemented a MongoDB database schema for efficient management of user profiles, 
      product catalogs, and transaction data, ensuring high performance and scalability.
      <br><br>
      ❂ Developed the platform's frontend using React creating a dynamic and interactive 
      shopping experience with real-time product updates and smooth navigation.
      <br><br>
      ❂ Utilized Redux for efficient state management across the platform, enabling 
      features like real-time cart updates, user authentication, and order tracking.
      <br><br>
      ❂ Implemented CSS Modules and React Bootstrap to ensure the platform's design 
      was cohesive, modern, and fully responsive across different devices and screen sizes.
      <br><br>
      ❂ Collaborated with the backend development team to integrate APIs, 
      ensuring seamless communication between the frontend and backend systems.
      <br><br>
      ❂ Integrated secure authentication and authorization mechanisms using JWT, 
      safeguarding user data and enhancing platform security.`,
      demoLink: "https://www.triotech.shop/",
    },
    stack: [
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "ExpressJs",
        icon: <SiExpress />,
      },
      {
        name: "MongoDB",
        icon: <SiMongodb />,
        iconColor: "limegreen",
      },
      {
        name: "ReactJs",
        icon: <DiReact />,
        iconColor: "skyblue",
      },
      {
        name: "JWT",
        icon: <SiJsonwebtokens />,
        iconColor: "orangered",
      },
      {
        name: "StripeJs",
        icon: <FaCcStripe />,
        iconColor: "blue",
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "skyblue",
      },
    ],
  },
  {
    id: 2,
    title: "Business Management and Subscription System",
    image: erm_img,
    category: "Freelance",
    data: {
      description: `● Led the design and implementation of a responsive, 
        user-centric frontend for a business management and subscription service platform,
        employing React and React Router for an engaging SPA experience.
        <br><br>
        ● Developed a RESTful API using Node.js and Express, enabling seamless 
        communication between the frontend and backend systems and facilitating 
        data retrieval and manipulation.
        <br><br>
        ● Designed and implemented a MongoDB database schema for efficient data 
        storage and retrieval, ensuring high performance and scalability for the platform.
        <br><br>
        ● Utilized Bootstrap and React Bootstrap to craft a consistent and aesthetically
        pleasing user interface, enhancing accessibility and user engagement across diverse devices.
        <br><br>
        ● Integrated Chart.js to create interactive and informative data visualizations,
        providing valuable business insights and aiding in data-driven decision-making.
        <br><br>
        ● Implemented user authentication and authorization using JWT, 
        enhancing platform security and ensuring secure access to sensitive data and features.
        <br><br>
        ● Collaborated closely with backend developers to ensure seamless integration of RESTful APIs,
        contributing to a unified development effort that significantly improved project cohesion and functionality.`,
      demoLink: "https://www.trioteachinvoice.today/",
    },
    stack: [
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "ExpressJs",
        icon: <SiExpress />,
      },
      {
        name: "MongoDB",
        icon: <SiMongodb />,
        iconColor: "limegreen",
      },
      {
        name: "ReactJs",
        icon: <DiReact />,
        iconColor: "skyblue",
      },
      {
        name: "JWT",
        icon: <SiJsonwebtokens />,
        iconColor: "orangered",
      },
      {
        name: "ChartJs",
        icon: <SiChartdotjs />,
        iconColor: "brown",
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "skyblue",
      },
    ],
  },
  {
    id: 3,
    title: "Manva Trade Inc. Website",
    image: mavna_trade,
    category: "Freelance",
    data: {
      description: `● Developed a comprehensive React-based web application, 
      integrating Firebase for user authentication, ensuring secure login processes 
      and protected routes for user-specific content access.
      <br><br>
      ● Designed and implemented responsive user interfaces using React, 
      custom CSS, and external libraries, ensuring a seamless and intuitive 
      user experience across various devices and platforms.
      <br><br>
      ● Engineered a robust file management system allowing for the upload, storage, 
      and real-time retrieval of multimedia content (PDFs, images, slides) using 
      Firebase storage and Firestore database, enhancing the administrative capabilities of the platform.
      <br><br>
      ● Implemented user authentication and authorization using JWT, ensuring secure access to rooms and user data.
      <br><br>
      ● Crafted an engaging contact section with email, WhatsApp, and social media 
      integration using EmailJS and React Icons, enabling direct and versatile communication 
      channels for client interaction and networking.`,
      demoLink: "https://mavna-trade-inc.vercel.app/",
    },
    stack: [
      {
        name: "ReactJs",
        icon: <DiReact />,
        iconColor: "skyblue",
      },
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "ExpressJs",
        icon: <SiExpress />,
      },
      {
        name: "Firebase",
        icon: <SiFirebase />,
        iconColor: "yellow",
      },
      {
        name: "EmailJS",
        icon: <MdMarkEmailRead />,
        iconColor: "orange",
      },
      {
        name: "JWT",
        icon: <SiJsonwebtokens />,
        iconColor: "orangered",
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "skyblue",
      },
    ],
  },
  {
    id: 4,
    title: "Chat App",
    image: chat_img,
    category: "Apps",
    data: {
      description: `⦿ Developed 'Chat-App-2024', a real-time chat application facilitating
       instant messaging across user-created chat rooms, utilizing Node.js, Express, 
       and Socket.io for efficient, bidirectional communication.
       <br><br>
      ⦿ Engineered a robust room functionality, enabling users to create or join multiple
       chat rooms for private discussions, with real-time updates of active 
       participants to enhance user engagement.
      <br><br>
      ⦿ Designed and delivered a user-friendly interface with responsive HTML/CSS/JS,
       providing intuitive navigation and interaction within the signup,
      login, and chat functionalities.
      <br><br>
      ⦿ Implemented user authentication and authorization using JWT, 
      ensuring secure access to chat rooms and user data.
      <br><br>
      ⦿ Collaborated with backend developers to integrate Socket.io 
      for real-time communication between users, enabling instant message delivery and updates.
      <br><br>
      ⦿ Integrated MongoDB for message persistence, allowing for efficient storage and 
      retrieval of chat history and user profiles, ensuring data continuity and integrity across sessions.
      <br><br>
      ⦿ Implemented a dark mode feature using local storage and CSS variables, 
      allowing users to customize their chat experience and reduce eye strain in low-light environments.
      <br><br>
      ⦿ Utilized Git for version control and GitHub for project management, 
      ensuring seamless collaboration and codebase management across the development team.`,
      demoLink: "https://www.triotech.live/",
    },
    stack: [
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "Socket.io",
        icon: <SiSocketdotio />,
        iconColor: "limegreen",
      },
      {
        name: "MongoDB",
        icon: <SiMongodb />,
        iconColor: "limegreen",
      },
      {
        name: "JWT",
        icon: <SiJsonwebtokens />,
        iconColor: "orangered",
      },
      {
        name: "ExpressJs",
        icon: <SiExpress />,
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "skyblue",
      },
    ],
  },
  {
    id: 5,
    title: "Air Quality dataset",
    image: bonus_as,
    category: "School",
    data: {
      description: `❂ The objective of this project is to perform a regression 
      analysis on the Air Quality dataset using a neural network built with 
      Keras. The project involves data preprocessing, splitting the data into 
      training and testing sets, normalizing the data, building and training 
      the model, and evaluating its performance using k-fold cross-validation.
      <br><br>
      ❂ Dataset Import: The Air Quality dataset is imported using pandas and 
      unnecessary columns are dropped. The remaining columns are cleaned, 
      and missing or invalid values are handled appropriately.
      <br><br>
      ❂ DateTime Conversion: The Date and Time columns are combined into a 
      single DateTime column for better analysis.
      <br><br>
      ❂ Feature Selection: The final dataset includes DateTime, 
      CO (Carbon Monoxide), and Temperature (Temp) columns.
      <br><br>
      ❂ Train-Test Split: The dataset is split into training and testing 
      sets using an 80-20 split. The training set is used to train the 
      model, while the testing set is used to evaluate its performance.
      <br><br>
      ❂ Normalization: The training and testing data are normalized 
      using the mean and standard deviation of the training set 
      to ensure that the model performs well.
      <br><br>
      ❂ Neural Network Model: A sequential neural network model is built 
      using Keras. The model includes two hidden layers with 64 
      neurons each and 'relu' activation functions. The output 
      layer has a single neuron to predict the temperature.
      <br><br>
      ❂ Model Compilation: The model is compiled using the 'rmsprop' 
      optimizer, 'mse' loss function, and 'mae' as the metric 
      to measure the model's performance.
      <br><br>
      ❂ K-Fold Cross-Validation: The model is evaluated using 
      4-fold cross-validation. The dataset is split into 4 parts, 
      and the model is trained and validated on different combinations 
      of these parts. The mean absolute error (MAE) is calculated 
      for each fold and averaged to get an overall performance measure.
      <br><br>
      ❂ Utilized Git for version control and GitHub for project`,
      demoLink:
        "https://github.com/arusafa/Fashion_MNIST_dataset_Machine_learning",
    },
    stack: [
      {
        name: "NumPy",
        icon: <SiNumpy />,
        iconColor: "limegreen",
      },
      {
        name: "Pandas",
        icon: <SiPandas />,
        iconColor: "brown",
      },
      {
        name: "Python",
        icon: <SiPython />,
        iconColor: "yellow",
      },
      {
        name: "Keras",
        icon: <SiKeras />,
        iconColor: "orangered",
      },
      {
        name: "Matplotlib",
        icon: <SiChartdotjs />,
        iconColor: "blue",
      },
      {
        name: "Scikit-learn",
        icon: <SiScikitlearn />,
        iconColor: "yellow",
      },
    ],
  },
  {
    id: 6,
    title: "Olive Trees  - App Design",
    image: olive_trees,
    category: "Freelance",
    data: {
      description: `● Developed a product detail page using the provided
       code template, which follows modern web development best practices 
       and utilizes Vue.js framework.
       <br><br>
      ● Implemented a responsive and visually appealing user interface using HTML,
       CSS, and Vue.js components, with a focus on providing an intuitive user experience.
        <br><br>
      ● Implemented back-end functionality using NodeJs and MongoDb for database connectivity.
        <br><br>
      ● Integrated Firebase Authentication for user sign-up and login functionality,
       leveraging the Firebase Auth API methods such as sendSignInLinkToEmail, 
       isSignInWithEmailLink, signInWithEmailLink, createUserWithEmailAndPassword,
        and signInWithEmailAndPassword.
        <br><br>
      ● Utilized Firebase's authentication features to send sign-in links via email,
       handle email verification, and authenticate users securely.
        <br><br>
      ● Implemented dynamic rendering of product details by making HTTP
       requests to the server API endpoints using the Axios library, specifically
        fetching data about the product being viewed and the user's cart items.
        <br><br>
      ● Hosted the Vue.js application on a web server, making it accessible to 
      users for viewing and interacting with the product details and cart functionality.
        <br><br>
      ● Utilized Git for version control and GitHub for project management, 
      ensuring seamless collaboration and codebase management across the development team.`,
      demoLink: "https://github.com/arusafa/Vue-Project_crud_APP",
    },
    stack: [
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "VueJs",
        icon: <FaVuejs />,
        iconColor: "green",
      },
      {
        name: "MongoDB",
        icon: <SiMongodb />,
        iconColor: "limegreen",
      },
      {
        name: "Firebase",
        icon: <SiFirebase />,
        iconColor: "yellow",
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "skyblue",
      },
    ],
  },
  {
    id: 7,
    title: "Employee Management System",
    image: employee_mng_img,
    category: "School",
    data: {
      description: `❂ Collaborated with a team to design and develop 
      a full-stack CRUD (Create, Read, Update, Delete) application system 
      for managing employees, following software development best practices and agile methodologies.
      <br><br>
      ❂ Utilized Bootstrap for front-end development, creating a responsive and user-friendly 
      interface that streamlined the process of adding, editing, and managing employee records.
      <br><br>
      ❂ Developed the front-end using React, implementing state management with Redux and 
      reusable components to optimize performance and maintainability.
      <br><br>
      ❂ Implemented back-end functionality using Node.js and Express, handling user authentication,
       RESTful API design, and CRUD operations for managing employee data.
      <br><br>
      ❂ Integrated MongoDB as a NoSQL database solution, using Mongoose for 
      schema modeling and data validation, ensuring data integrity and security.
      <br><br>
      ❂ Implemented input validation and error handling on both front-end 
      and back-end to improve overall application reliability, leading 
      to a more robust and user-friendly system.
      <br><br>
      ❂ Utilized Git for version control and GitHub for project`,
      demoLink: "https://google.com/",
    },
    stack: [
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "ReactJs",
        icon: <DiReact />,
        iconColor: "skyblue",
      },
      {
        name: "Redux",
        icon: <SiRedux />,
        iconColor: "orangered",
      },
      {
        name: "MongoDB",
        icon: <SiMongodb />,
        iconColor: "limegreen",
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
      {
        name: "JWT",
        icon: <SiJsonwebtokens />,
        iconColor: "orangered",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "skyblue",
      },
    ],
  },
  {
    id: 8,
    title: "Fashion MNIST dataset",
    image: data_as_1,
    category: "School",
    data: {
      description: `❂ The objective of this project is to build and train a neural network
      to classify images from the Fashion MNIST dataset. This dataset consists of grayscale
      images of fashion items, such as T-shirts, trousers, and dresses, and is intended as a 
      drop-in replacement for the original MNIST dataset of handwritten digits. 
      The project involves multiple steps including data loading, preprocessing, 
      model creation, training, and evaluation.
      <br><br>
      ❂ Data Loading and Inspection: The Fashion MNIST dataset includes 60,000 training 
      images and 10,000 test images, each of size 28x28 pixels. The images are categorized 
      into 10 different classes such as T-shirt/top, Trouser, Pullover, Dress, Coat, 
      Sandal, Shirt, Sneaker, Bag, and Ankle boot.
      <br><br>
      ❂ Data Exploration: The dimensions of the training and test images and their 
      corresponding labels are printed to understand the dataset structure. 
      Displaying a few sample images and their corresponding labels helps in 
      visualizing the type of fashion items included in the dataset.
      <br><br>
      ❂ Model Construction: A neural network is created using Keras' Sequential model. 
      This includes a Flatten layer to convert 28x28 images into a 784-length vector, 
      hidden layers with 512 and 128 neurons using the 'relu' activation function, 
      and a final output layer using 'softmax' activation with 10 neurons to 
      classify the images into one of the 10 categories.
      <br><br>
      ❂ Training and Compilation: The images are normalized by dividing the 
      pixel values by 255. Two sets of compilation settings are tested: 
      one with the Adam optimizer, sparse_categorical_crossentropy loss, 
      and accuracy metrics; and another with the RMSprop optimizer, 
      categorical_crossentropy loss, and accuracy metrics. 
      The model is then trained using the fit method for 10 epochs.
      <br><br>
      ❂ Evaluation and Comparison: The model's performance is evaluated 
      on the test set to determine the test accuracy and loss. 
      The accuracy is compared between training and test data to 
      check for overfitting. The complexity of Fashion MNIST compared 
      to MNIST is discussed, highlighting the increased number 
      of classes and the challenges it poses for classification.
      <br><br>
      ❂ Utilized Git for version control and GitHub for project`,
      demoLink:
        "https://github.com/arusafa/Fashion_MNIST_dataset_Machine_learning",
    },
    stack: [
      {
        name: "Python",
        icon: <SiPython />,
        iconColor: "yellow",
      },
      {
        name: "Keras",
        icon: <SiKeras />,
        iconColor: "orangered",
      },
      {
        name: "Matplotlib",
        icon: <SiChartdotjs />,
        iconColor: "blue",
      },
      {
        name: "Jupyter Notebook",
        icon: <SiJupyter />,
        iconColor: "limegreen",
      },
    ],
  },
  {
    id: 9,
    title: "Airlines Management System",
    image: gbc_airlines,
    category: "Apps",
    data: {
      description: `⦿ Collaborated with a 3-person team to design and 
      implement a user-friendly airport booking application in C#, 
      adhering to software development best practices and agile methodologies.
      <br><br>
      ⦿ Contributed to front-end development using WPF (Windows Presentation Foundation)
      to create an intuitive user interface, allowing users to seamlessly navigate through the booking process.
      <br><br>
      ⦿ Developed back-end functionality for handling user authentication, booking,
       and editing of reservation details, ensuring data integrity and security through 
       the use of Entity Framework and SQL Server for database management.
      <br><br>
      ⦿ Enabled users to search, book, and modify reservations from a database 
      of over 50 mock airline tickets, incorporating flight details, 
      seat availability, and pricing information.
      <br><br>
      ⦿ Implemented a user-friendly interface for the admin to manage 
      flight details, including adding, editing, and deleting flights from the database.
      <br><br>
      ⦿ Conducted rigorous testing and debugging to ensure a bug-free experience, 
      resulting in a positive user feedback and an increase in overall booking efficiency..
      <br><br>
      ⦿ Utilized Git for version control and GitHub for project`,
      demoLink:
        "https://github.com/arusafa/Airlines_Booking_Sys_WindowsForm-cSharp",
    },
    stack: [
      {
        name: "C#",
        icon: <PiFileCSharpFill />,
        iconColor: "greenyellow",
      },
      {
        name: "SQL Server",
        icon: <TbFileTypeSql />,
        iconColor: "skyblue",
      },
      {
        name: "WPF",
        icon: <FaWpforms />,
        iconColor: "purple",
      },
      {
        name: "Microsoft Entity",
        icon: <SiMicrosoftaccess />,
        iconColor: "limegreen",
      },
    ],
  },
  {
    id: 10,
    title: "Sporting Management System",
    image: gbc_sporting,
    category: "Apps",
    data: {
      description: `● Collaborated with a 4-person team to design and implement 
      a .NET-based CRUD (Create, Read, Update, Delete) application system, 
      following software development best practices and agile methodologies.
      <br><br>
      ● Utilized ASP.NET MVC for front-end development, creating a responsive
       and user-friendly interface that streamlined the booking and editing 
       processes for various sporting events and venues.
      <br><br>
      ● Developed back-end functionality using C# and Entity Framework for 
      database connectivity, handling user authentication, and managing 
      CRUD operations with a SQL Server database to ensure data integrity and security.
      <br><br>
      ● Enabled users to search, book, and modify reservations from a database 
      of 50+ mock addresses, incorporating venue details, event availability, 
      and pricing information for an enhanced user experience.
      <br><br>
      ● Integrated input validation and error handling to improve overall 
      application reliability, leading to a more robust and user-friendly system.
      <br><br>
      ● Conducted rigorous testing and debugging to ensure a bug-free experience, 
      resulting in a positive user feedback and an increase in overall booking efficiency..
      <br><br>
      ● Utilized Git for version control and GitHub for project`,
      demoLink: "https://github.com/arusafa/GBC-SportingTeam_Amigos",
    },
    stack: [
      {
        name: ".NET Core",
        icon: <SiMicrosoft />,
        iconColor: "purple",
      },
      {
        name: "SQL Server",
        icon: <TbFileTypeSql />,
        iconColor: "skyblue",
      },
      {
        name: "C#",
        icon: <PiFileCSharpFill />,
        iconColor: "blue",
      },
      {
        name: "Microsoft Entity",
        icon: <SiMicrosoftaccess />,
        iconColor: "limegreen",
      },
    ],
  },
  {
    id: 11,
    title: "Convolutional Neural Networks",
    image: data_as_3,
    category: "School",
    data: {
      description: `⦿ The objective of this project is to deepen the understanding 
      of Convolutional Neural Networks (CNNs) by exploring the concepts and 
      practical applications of image convolutions and training a neural 
      network on the CIFAR-10 dataset. 
      The project is divided into two parts: Part A focuses on understanding 
      2D convolution kernels, while Part B involves training a 
      neural network to classify images.
      <br><br>
      ⦿ Part A: Image Convolutions and Kernels
      <br><br>
      ⦿ Import and Visualization: The project begins by importing necessary 
      libraries such as NumPy, PIL, and Matplotlib. An image is fetched 
      from a URL and converted into a 3D array representing RGB color 
      channels. The image is then visualized and its shape is printed.
      <br><br>
      ⦿ Convolution with Kernels: The project demonstrates how to apply a 
      2D convolution kernel to an image. Various kernels are used to 
      detect horizontal, vertical, and diagonal lines. 
      <br><br>
      ⦿ Functions are defined to apply these kernels and visualize the 
      results, which helps in understanding how convolutions transform images.
      <br><br>
      ⦿ Edge Detection: Different edge detection kernels are applied to the 
      image to highlight edges. This part explains how convolutional 
      operations can be used for feature extraction in image processing tasks.
      <br><br>
      ⦿ Part B: Training a Neural Network:
      <br><br>
      ⦿ Dataset Preparation: The CIFAR-10 dataset, consisting of 60,000 
      32x32 color images in 10 classes, is loaded. The images and labels 
      are preprocessed, including normalization and one-hot encoding.
      <br><br>
      ⦿ Model Construction: A Convolutional Neural Network (CNN) is 
      built using Keras. The model includes Conv2D layers, MaxPooling2D 
      layers, a Flatten layer, and Dense layers. The model is compiled 
      with the Adam optimizer and categorical crossentropy loss function.
      <br><br>
      ⦿ Training and Evaluation: The model is trained for 50 epochs 
      with a batch size of 256, and a custom callback is used to log 
      the training progress. The accuracy and loss are plotted to 
      visualize the training process. Modifications such as adding 
      more convolutional layers and dropout layers are experimented 
      with to improve the model's performance.
      <br><br>
      ⦿ Utilized Git for version control and GitHub for project`,
      demoLink:
        "https://github.com/arusafa/Convolutional_Neural_Networks_Machine_learning",
    },
    stack: [
      {
        name: "Python",
        icon: <SiPython />,
        iconColor: "yellow",
      },
      {
        name: "Pandas",
        icon: <SiPandas />,
        iconColor: "brown",
      },
      {
        name: "Google Colab",
        icon: <SiGooglecolab />,
        iconColor: "orange",
      },
      {
        name: "NumPy",
        icon: <SiNumpy />,
        iconColor: "orangered",
      },
      {
        name: "Keras",
        icon: <SiKeras />,
        iconColor: "orangered",
      },
      {
        name: "Matplotlib",
        icon: <SiChartdotjs />,
        iconColor: "blue",
      },
      {
        name: "Scikit-learn",
        icon: <SiScikitlearn />,
        iconColor: "yellow",
      },
    ],
  },
  {
    id: 12,
    title: "Employee Management System",
    image: employee_typescript,
    category: "School",
    data: {
      description: `● The employee management system is designed 
      to facilitate seamless management of employee information. 
      The application supports various functionalities, including 
      listing all employees, adding new employees, editing existing 
      employee details, and deleting employees. Additionally, the 
      system incorporates user authentication to ensure secure access 
      and management of employee data. The backend is powered by GraphQL, 
      providing a flexible and efficient data querying mechanism.
      <br><br>
      ● Employee Management: The system allows users to view, add, edit, 
      and delete employee records. Each employee record includes details 
      such as first name, last name, email, gender, and salary.
      <br><br>
      ● User Authentication: The project includes user authentication features,
       enabling users to register, login, and logout securely. 
       Authentication queries and mutations are implemented using GraphQL.
      <br><br>
      ● Apollo Integration: Apollo Angular is utilized to manage GraphQL 
      queries and mutations, providing a seamless integration 
      between the frontend and the backend.
      <br><br>
      ● Form Validation: The system uses Angular Reactive Forms for 
      handling user input with validation rules to ensure data 
      integrity and consistency.
      <br><br>
      ● Real-time Data Updates: The application ensures real-time 
      updates and reflects the latest data by fetching employee 
      lists and other data dynamically through GraphQL queries.
      <br><br>
      ● Utilized Git for version control and GitHub for project`,
      demoLink: "https://github.com/arusafa/Emp_Mng_System_TypeScript",
    },
    stack: [
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "Angular",
        icon: <SiAngular />,
        iconColor: "skyblue",
      },
      {
        name: "ApolloGraphQL",
        icon: <SiApollographql />,
        iconColor: "purple",
      },
      {
        name: "AngularMaterial",
        icon: <RiAngularjsLine />,
        iconColor: "orangered",
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
      {
        name: "JWT",
        icon: <SiJsonwebtokens />,
        iconColor: "orangered",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "skyblue",
      },
    ],
  },
  {
    id: 13,
    title: "Recipe Management System",
    image: recipe_app,
    category: "School",
    data: {
      description: `❂ Collaborated with a team to design and build a 
      Spring Boot-based CRUD (Create, Read, Update, Delete) 
      application system for managing recipes, following software 
      development best practices and agile methodologies.
      <br><br>
      ❂ Utilized Thymeleaf and Bootstrap for front-end development, 
      creating a responsive and visually appealing user interface 
      that streamlined the process of adding, editing, and managing recipes.
      <br><br>
      ❂ Developed back-end functionality using Java, Spring Boot, 
      and Spring Data JPA for database connectivity, handling user 
      authentication, and managing CRUD operations with a 
      H2 in-memory database to ensure data integrity and security.
      <br><br>
      ❂ Enabled users to search, add, edit, and delete recipes 
      from a database of 10+ mock recipes, incorporating detailed 
      recipe information such as ingredients, instructions, 
      and nutritional facts for an enhanced user experience.
      <br><br>
      ❂ Implemented input validation and error handling to 
      improve overall application reliability, leading to a 
      more robust and user-friendly system.
      <br><br>
      ❂ Conducted rigorous testing and debugging to ensure a bug-free experience, 
      resulting in a positive user feedback and an increase in overall booking efficiency..
      <br><br>
      ❂ Utilized Git for version control and GitHub for project`,
      demoLink: "https://github.com/arusafa/GBC-SportingTeam_Amigos",
    },
    stack: [
      {
        name: "Spring Boot",
        icon: <SiSpringboot />,
        iconColor: "green",
      },
      {
        name: "Java",
        icon: <FaJava />,
        iconColor: "orangered",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "yellow",
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
    ],
  },
  {
    id: 14,
    title: "Food Finder App",
    image: food_finder,
    category: "Apps",
    data: {
      description: `● Collaborated with a team to design and develop 
      a mobile CRUD (Create, Read, Update, Delete) application 
      system for finding and booking restaurants, following 
      software development best practices and agile methodologies.
      <br><br>
      ● Utilized React Native for cross-platform mobile app development,
       creating a responsive and user-friendly interface that streamlined 
       the process of searching, booking, and managing restaurant 
       information as well as rates on Yelp..
      <br><br>
      ● Developed reusable components and implemented state management 
      using Redux, optimizing performance and maintainability 
      across both Android and iOS platforms.
      <br><br>
      ● Enabled users to search, add, edit reviews, 
      from a database of 80+ mock restaurants, incorporating 
      detailed information such as location, cuisine, pricing, 
      and availability for an enhanced user experience.
      <br><br>
      ● Implemented input validation and error handling to 
      improve overall application reliability, leading to a 
      more robust and user-friendly system.
      <br><br>
      ● Conducted rigorous testing and debugging to ensure a bug-free experience, 
      resulting in a positive user feedback and an increase in overall booking efficiency..
      <br><br>
      ● Utilized Git for version control and GitHub for project`,
      demoLink: "https://github.com/arusafa/FoodFinder",
    },
    stack: [
      {
        name: "React Native",
        icon: <TbBrandReactNative />,
        iconColor: "skyblue",
      },
      {
        name: "Redux",
        icon: <SiRedux />,
        iconColor: "orangered",
      },
      {
        name: "Google Maps",
        icon: <SiGooglemaps />,
        iconColor: "yellow",
      },
      {
        name: "Google API",
        icon: <FaGooglePlusSquare />,
        iconColor: "red",
      },
      {
        name: "Bootstrap",
        icon: <FaBootstrap />,
        iconColor: "purple",
      },
      {
        name: "CSS",
        icon: <BiLogoCss3 />,
        iconColor: "skyblue",
      },
    ],
  },
  {
    id: 15,
    title: "Iris dataset",
    image: data_as_2,
    category: "School",
    data: {
      description: `● The objective of this project is to build and train a neural 
      network to classify iris flower species using the famous Iris dataset. 
      The project involves data loading, preprocessing, model creation, training, 
      and evaluation using Google Colab and a neural network built with Keras. 
      The steps are detailed below:
      <br><br>
      ● Data Loading and Preparation: The Iris dataset is first uploaded to 
      Google Drive and then loaded into a pandas dataframe in the Colab 
      environment. The last column containing the species names is converted 
      to integer labels (setosa -> 0, versicolor -> 1, virginica -> 2) 
      to facilitate training.
      <br><br>
      ● Data Splitting: The dataset is split into features (x) and labels (y). 
      These are further divided into training and testing sets using a 80-20 
      split to ensure the model is evaluated on unseen data.
      <br><br>
      ● Model Construction: A neural network is built using Keras' 
      Sequential model. The initial model contains three dense layers: 
      the first two with 100 neurons and 'relu' activation, and the 
      final output layer with 3 neurons and 'softmax' 
      activation to classify the species.
      <br><br>
      ● Training and Compilation: The model is compiled using the 
      'rmsprop' optimizer, 'categorical_crossentropy' loss function, 
      and 'accuracy' as the metric. The model is trained for 
      20 epochs with a batch size of 10.
      <br><br>
      ● Evaluation and Experimentation: The model's performance is 
      evaluated on the test set, yielding accuracy and loss metrics. 
      Further experimentation involves adjusting the model by 
      reducing the number of neurons and epochs, demonstrating 
      the impact of these changes on model performance and overfitting.
      <br><br>
      ● Utilized Git for version control and GitHub for project`,
      demoLink: "https://github.com/arusafa/Iris_dataset_Machine_learning",
    },
    stack: [
      {
        name: "Google Colab",
        icon: <SiGooglecolab />,
        iconColor: "orange",
      },
      {
        name: "Python",
        icon: <SiPython />,
        iconColor: "yellow",
      },
      {
        name: "Pandas",
        icon: <SiPandas />,
        iconColor: "brown",
      },
      {
        name: "Keras",
        icon: <SiKeras />,
        iconColor: "orangered",
      },
    ],
  },
];

export const experience = [
  {
    title: "Backend Development",
    data: [
      {
        skill: "Node JS",
        level: "Experienced",
      },
      {
        skill: "MangoDB",
        level: "Experienced",
      },
      {
        skill: "Java",
        level: "Intermediate",
      },
      {
        skill: "Python",
        level: "Intermediate",
      },
      {
        skill: "ASP.NET",
        level: "Intermediate",
      },
      {
        skill: "C#",
        level: "Intermediate",
      },
    ],
  },
  {
    title: "Frontend Development",
    data: [
      {
        skill: "JavaScript",
        level: "Experienced",
      },
      {
        skill: "ReactJS",
        level: "Experienced",
      },
      {
        skill: "AngularJS",
        level: "Experienced",
      },
      {
        skill: "HTML",
        level: "Intermediate",
      },
      {
        skill: "Tailwind",
        level: "Experienced",
      },
      {
        skill: "Bootstrap",
        level: "Experienced",
      },
      {
        skill: "CSS",
        level: "Experienced",
      },
    ],
  },
  {
    title: "Database",
    data: [
      {
        skill: "MongoDB",
        level: "Experienced",
      },
      {
        skill: "Firebase",
        level: "Experienced",
      },
      {
        skill: "GraphQL",
        level: "Intermediate",
      },
      {
        skill: "MySQL",
        level: "Intermediate",
      },
      {
        skill: "SQL Server",
        level: "Intermediate",
      },
    ],
  },
];

export const socialHandles = [
  {
    name: "Github",
    icon: <AiFillGithub />,
    link: "https://github.com/arusafa",
  },
  {
    name: "LinkedIn",
    icon: <AiFillLinkedin />,
    link: "https://www.linkedin.com/in/safaaru/",
  },
  {
    name: "Instagram",
    icon: <FaSquareInstagram />,
    link: "https://www.instagram.com/safaaru/",
  },
  {
    name: "Facebook",
    icon: <FaFacebookSquare />,
    link: "https://www.facebook.com/safa.aru/",
  },
];
